import notify from "devextreme/ui/notify";
import { useCallback, useEffect, useRef, useState } from "react";
import CardForm from "../../../Components/CardForm/CardForm";
import PopUpModel from "../../../Components/PopUpModel/PopUpModel";
import YesOrNoPopUp from "../../../Components/YesOrNoPopUp/YesOrNoPopUp";

import {
  QUESTIONS,
  QUESTIONS_DELETE,
  QUESTIONS_INSERT,
  QUESTIONS_UPDATE,
} from "../API";
import QuestionsForm from "./QuestionsForm";

function Questions() {
  let [data, setdata] = useState([]);
  let defualtValues = useRef({
    Id: 0,

    QuestionText: "",
    QuestionTextEn: "",
    Answer: "",
    AnswerEn: "",
    Active: false,
  });
  let [YesOrNo, setYesOrNo] = useState({ id: 0, state: false });
  let [popup, setpopup] = useState({ data: {}, status: false });
  useEffect(async () => {
    await QUESTIONS()
      .then((res) => {
        setdata(res);
      })
      .catch(() => {});
  }, []);
  const OnDelete = useCallback(
    async (id) => {
      if (id <= 0) {
        notify("Error in information. select Again! ");
        return;
      }
      await QUESTIONS_DELETE(id)
        .then(() => {
          let x = data.filter(function (el) {
            return el.Id !== id;
          });
          setdata(x);
          notify("Deleted successfuly", "success", 3000);
        })
        .catch(() => {
          notify("Error in information. try again! ", "error", 3000);
        });
    },
    [data]
  );

  const OnSubmit = useCallback(
    async (val) => {
      let formData = new FormData();

      for (let [key, value] of Object.entries(val)) {
        formData.append(key.toString(), value);
      }
      if (val.Id === 0) {
        await QUESTIONS_INSERT(val)
          .then((res) => {
            notify("Added successfuly", "success", 3000);
            data.push(res);
            setdata(data);
            setpopup({ data: {}, status: false });
          })
          .catch(() => {
            notify("Error in information. try again! ", "error", 3000);
          });
      } else {
        await QUESTIONS_UPDATE(val)
          .then((res) => {
            notify("Updated successfuly", "success", 3000);
            setdata(
              data.map((ele) => {
                return ele.Id === val.Id ? { ...res } : { ...ele };
              })
            );
            setpopup({ data: {}, status: false });
          })
          .catch(() => {
            notify("Error in information. try again! ", "error", 3000);
          });
      }
    },
    [data]
  );

  return (
    <div>
      <CardForm title=" Questions">
        <div
          className="Add-icon-form "
          onClick={() =>
            setpopup({ data: defualtValues.current, status: !popup.status })
          }
        >
          <i className="far fa-plus-square"></i>
        </div>
        {data.map((res, index) => {
          return (
            <div key={index} className="row Home-benefits-card">
              <div
                className="col-lg-10 col-md-9 col-sm-12"
                onClick={() => setpopup({ data: res, status: !popup.status })}
              >
                <div className="step-card-title">{res.QuestionText}</div>
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-2 delete-icon-crud"
                onClick={() => setYesOrNo({ id: res.Id, state: true })}
              >
                <i className=" fas fa-trash-alt"></i>
              </div>
            </div>
          );
        })}
      </CardForm>

      <QuestionsForm
        status={popup.status}
        setdialog={(e) =>
          setpopup(e ? { ...popup, status: e } : { data: {}, status: e })
        }
        OnSubmit={OnSubmit}
        data={popup.data}
      />

      <YesOrNoPopUp
        dailog={YesOrNo}
        setdialog={useCallback(
          (e) => setYesOrNo((prev) => ({ ...prev, state: e })),
          []
        )}
        OnDelete={OnDelete}
      />
    </div>
  );
}

export default Questions;
