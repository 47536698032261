import AboutUs from "../Views/AboutUs/AbourUs";
import ContactUs from "../Views/ContactUs/ContactUs";
import FAQ from "../Views/FAQ";
import Footer from "../Views/Footer";
import Home from "../Views/Home/Home";
import Posts from "../Views/Posts";

export const routes = [
  {
    path: "/",
    name: "Home",
    icon: "fas fa-home",
    component: Home,
    layout: "",
  },
  {
    path: "/About-us",
    name: "About us",
    icon: "fas fa-user-edit",
    component: AboutUs,
    layout: "",
  },

  {
    path: "/FAQ",
    name: "FAQ",
    icon: "fas fa-solid fa-question",
    component: FAQ,
    layout: "",
  },
  {
    path: "/posts",
    name: "Posts",
    icon: "fas fa-newspaper",
    component: Posts,
    layout: "",
  },
  {
    path: "/Contact-us",
    name: "Contact us",
    icon: "far fa-address-book",
    component: ContactUs,
    layout: "",
  },
  {
    path: "/Footer",
    name: "Footer",
    icon: "fas fa-home",
    component: Footer,
    layout: "",
  },
];
