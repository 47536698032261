import React from "react";
import PostsList from "./Component/PostsList";

function Posts() {
  return (
    <div className="content">
      <PostsList />
    </div>
  );
}

export default Posts;
