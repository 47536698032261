import "./AboutUs.css";
import Branches from "./Components/Branches";
import Partners from "./Components/Partners";
import Section from "./Components/Section";
import Service from "./Components/Service";

function AboutUs() {
  return (
    <div className="content">
      <Section />
      <Partners />
      <Service />
      <Branches />
    </div>
  );
}

export default AboutUs;
