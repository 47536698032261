import Benefits from "./Components/Benefits";
import Header from "./Components/Header";
import Member from "./Components/Members";
import "./Home.css";

function Home() {
  return (
    <div className="content">
      <Header />
      <Benefits />
    </div>
  );
}

export default Home;
