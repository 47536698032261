import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { FormGroup } from "reactstrap";
import InputTwoLanguages from "../../../Components/InputTwoLanguages/InputTwoLanguages";
import PopUpModel from "../../../Components/PopUpModel/PopUpModel";
import TextEditorTwoLanguages from "../../../Components/TextEditorTwoLanguages/TextEditorTwoLanguages";

function QuestionsForm({ data, status, setdialog, OnSubmit }) {
  let [values, setvalues] = useState({});
  useEffect(() => {
    console.log(data.Answer);
    console.log(data.AnswerEn);
    //data.Answer = data.Answer?.replaceAll("</p><p>", "<br>");
    // data.AnswerEn = data.AnswerEn?.replaceAll("</p><p>", "<br>");
    setvalues(data);
  }, [data]);
  const HandleChange = useCallback((value, id) => {
    setvalues((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);

  return (
    <PopUpModel
      setdialog={(e) => {
        setvalues({});
        setdialog(e);
      }}
      dailog={status}
      Submit={() => OnSubmit(values)}
    >
      <div style={{ width: "100%" }}>
        <FormGroup>
          <InputTwoLanguages
            id="QuestionText"
            label={"Question"}
            onValueChange={HandleChange}
            value={values?.QuestionText}
            valueEn={values?.QuestionTextEn}
          />
        </FormGroup>
        <FormGroup>
          <TextEditorTwoLanguages
            id="Answer"
            label={"Answer"}
            onValueChange={HandleChange}
            valueEn={values.AnswerEn}
            value={values.Answer}
          />
        </FormGroup>
        <div className="row">
          <div className="col">
            <FormGroup>
              <label htmlFor="isActive" className="d-block">
                Is Active
              </label>
              <input
                style={{
                  width: "40px",
                  height: "40px",
                }}
                type="checkbox"
                checked={values?.Active}
                id="Active"
                onChange={useCallback(
                  (e) => HandleChange(e.target.checked, e.target.id),
                  [HandleChange]
                )}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </PopUpModel>
  );
}

export default QuestionsForm;
