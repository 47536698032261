import React from "react";
import Questions from "./Components/Questions";
import Section from "./Components/Section";

function FAQ() {
  return (
    <div className="content">
      <Questions />
      <Section />
    </div>
  );
}

export default FAQ;
